/* eslint-disable no-unused-vars */
import React from "react"
import { withPreviewResolver } from "gatsby-source-prismic"

import Layout from "../components/Layout"

// const linkResolver = doc => {
//   console.log(doc.node.type)
//   if (doc.node.type === "home") return `/`

//   return `/${doc.node.uid}`
// }

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return "Not a preview!"

  return (
    <Layout>
      <p>Loading</p>
    </Layout>
  )
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: "risingyouth",
  linkResolver: ({ node, key, link }) => doc => {
    if (doc.type === "home") return `/`

    return `/${doc.uid}`
  },
})
